@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;500&family=Nunito+Sans:wght@700&family=Rubik:wght@300;400&display=swap');

* {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #141b20; */
  background-image: url('../images/bg-body.png');
  background-color: #000000;
  background-size: contain;
  background-position: center;
  color: #fff;
  width: 100%;
  overflow-x: hidden;
  font-family: 'Mulish', sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, Montserrat, sans-serif; */
}

*,
body {
  margin: 0;
  padding: 0;
}

.br_25{
  border-radius: 25px;
}


.headerLogo {
  height: 80px;
}

.font-w500 {
  font-weight: 500 !important;
}

.font-w400 {
  font-weight: 400 !important;
}

.font-w600 {
  font-weight: 600 !important;
}

.nav_links {
  background: #434343;
  padding: 0.2rem 1rem;
  border: 0.7px solid #fff;
  border-radius: 8px;
  color: #fff;
}

.nav_active {
  color: #3c70db !important;
  font-weight: 600 !important;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: #fff !important;
}

.nav_link {
  color: #fff;
  margin: auto 0.5rem;
}

.nav_link {
  color: #fff;
  margin: auto 0.5rem;
  transition: all 0.4s;
}
.nav_link:hover {
  color: #fff;
  transform: scale(1.05);
  /* color: #325da8; */
}

.nav_icons {
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 10px;
  margin: 0rem 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #466db0;
}

.nav_icons i {
  transform: scale(1.2);
}

.landing_section {
  min-height: 90vh;
  position: relative;
  background: url('../images/bg.png');
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  /* display: block; */
}

.empty_header {
  height: 90px;
}

.landing_heading_section {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 80vh !important;
  flex-direction: column;
  margin: auto;
}

.landing_heading_section p {
  margin: 2rem 0rem;
  font-size: 1rem;
  font-weight: 300;
}

.landing_title {
  font-size: 3rem;
  /* margin-top: 1rem; */
  font-family: 'Nunito Sans', sans-serif;
}

.book_btn {
  display: inline-block;
  outline: none;
  width: 150px;
  color: #fff;
  background: #466db0;
  outline: none;
  border: none;
  padding: 0.8rem;
  font-weight: 500;
  border-radius: 10px;
  transition: all 0.5s;
}

.book_btn:hover {
  transform: scale(1.1);
  color: #fff !important;
}

.know_more_link {
  color: #fff;
  font-size: 1.1rem;
  display: inline-block;
  margin: auto 2rem;
  font-weight: 500;
}
/* .know_more_link:hover {
  color: #fff !important;
} */

.app-img {
  height: 50px;
}

.container_landing {
  width: 70%;
  margin: auto;
}

.steps_section {
  min-height: 100vh;
}

.steps_title {
  text-align: center;
  margin-bottom: 2rem;
  font-family: 'Rubik', sans-serif;
  font-size: 3rem;
  font-weight: 500 !important;
}

.process_steps {
  background-color: #3c72e1;
  border-radius: 20px;
  overflow: hidden;
}

.process_steps .step_img {
  background: #0c2455;
  border-radius: 20px;
  margin: 5px;
  border: 2px solid #fff;
  height: 300px;
  /* display: flex; */
}

.step_img img {
  object-fit: cover;
}

.step_tittle {
  padding: 1rem auto;
  font-weight: bold !important;
  margin: 1rem auto;
}

.step_desc {
  font-weight: lighter !important;
  padding: 0.5rem 2rem;
  font-size: 1.1rem;
}

.video1_section {
  margin: 5rem auto;
}

.explore_content {
  min-height: 300px;
  background: url(../images/explore.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 5rem;
}

.explore_content h1 {
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: 1px;
  font-size: 3rem;
}

.problems_heading {
  /* font-family: 'Rubik', sans-serif; */
  font-size: 3rem;
  font-weight: 600 !important;
  margin-bottom: 2rem;
}

.help_section .owl-theme .owl-nav,
.problems_section .owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: end;
  justify-content: end;
  margin-right: 5%;
  margin-bottom: 3%;
}

.help_section .owl-theme .owl-nav img,
.problems_section .owl-theme .owl-nav img {
  width: 30px;
}

.testimonial_box {
  background: #ffffff37;
  padding: 1rem 2rem;
  border-radius: 25px;
  /* margin: auto 1rem; */
}

.testimonial_box img {
  width: auto !important;
  height: 50px;
  /* width: auto; */
  object-fit: contain;
  margin-bottom: 1rem 0px !important;
}

.testimonial_img_section {
  margin: 1rem 0px !important;
}

.testimonial_heading {
  font-weight: 500;
  font-family: 'Mulish', sans-serif;
}

.testimonial_box p {
  font-weight: lighter !important;
  font-size: 0.9rem;
  font-family: 'Mulish', sans-serif;
  min-height: 130px;
}

.help_box {
  background: #3c72e1;
  border-radius: 25px;
  padding: 2rem;
}

.help_box .help_icon {
  width: 50px !important;
}

.help_section {
  margin-top: 5rem;
}

.help_box .help_heading {
  font-family: 'Mulish', sans-serif;
  font-size: 2rem;
  font-weight: 700 !important;
  letter-spacing: 1px;
}

.help_box p {
  font-size: 0.9rem;
  font-family: 'Mulish', sans-serif;
}

.contact_section {
  margin-top: 3rem;
}

.contact_section {
  font-family: 'Mulish', sans-serif;
  margin-top: 5rem;
}

.contact_desc {
  font-weight: lighter !important;
  color: grey;
}

.contact_section .contact_icons {
  margin: 0px 5px;
}

.contact_section .contact_img {
  width: 50%;
  margin-bottom: 2rem;
}

.contact_form {
  background-color: #636363;
  padding: 2rem;
  border-radius: 5px;
}

.contact_form label {
  display: block;
  font-weight: 400;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}

.contact_form .contact_input {
  display: block;
  width: 100%;
  background-color: #080808;
  outline: none;
  border: none;
  padding: 0.5rem;
  color: #fff;
  border-radius: 5px;
}

.contact_form .contact_input::placeholder {
  font-weight: lighter;
  font-size: 0.8rem;
  color: #fff;
}

.msg_btn {
  display: block;
  color: #fff;
  background-color: #3c72e1;
  width: 100%;
  outline: none;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  box-shadow: 0px 5px 8px #00000087;
}

.faq_section {
  background: #000000;
  margin-top: 5rem;
}

.faq_accordion .accordion-button,
.faq_accordion .accordion-header,
.faq_accordion .accordion-item,
.faq_accordion .accordion-body {
  background: #09050d !important;
  color: #fff;
}

.section_available {
  background: #000000;
  padding-top: 5rem;
  padding-bottom: 3rem;
}

.available_desc {
  color: grey;
}

.available_img {
  transform: scale(1.3);
}

footer {
  background: #000000;
}

.footer_links {
  font-size: 0.8rem;
}

.policy_links {
  font-weight: lighter;
}

.footer_copy {
  background-color: #0c0d0d;
}

.footer_desc {
  font-size: 0.9rem;
  font-weight: lighter;
}

.private_policy {
  font-size: 0.9rem;
  font-weight: lighter;
}

.video_section video {
  width: 100%;
  max-height: 65vh;
}

.video_section video {
  transform: translate(8%, 6.5%);
}

.video_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(150%, 50%) scale(2);
  border: none;
  outline: none;
  background-color: transparent;
  color: #fff;
  opacity: 0;
  transition: all 0.3s;
}

.video_section:hover .video_btn {
  opacity: 1;
}
@media only screen and (min-width: 771px){
  .video_border{
    position: relative;
      left: 40px;
  }
}

@media only screen and (max-width: 770px) {
  body {
    width: 100%;
    overflow-x: hidden;
  }

  .video_section video {
    width: 100%;
    max-height: auto;
  }

  .container_landing {
    width: 90%;
    margin: auto;
  }

  .headerLogo {
    height: 50px;
  }

  .empty_header {
    height: 70px;
  }
  .landing_heading_section {
    margin-top: 1rem;
  }
  .landing_title {
    font-size: 2rem;
  }
  .book_btn {
    width: 150px;
  }

  .know_more_link {
    margin: auto 0rem auto 1rem;
  }

  .app-img {
    /* height: 50px; */
    width: 120px;
  }

  .landing_section {
    background: none;
  }

  .steps_title {
    font-size: 1.5rem;
  }

  .process_steps .step_img {
    height: auto;
  }

  .step_tittle {
    margin: 0.5rem auto;
    font-size: 1.3rem;
  }

  .step_desc {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }

  .explore_content h1 {
    font-size: 1.5rem;
  }

  .explore_content {
    height: auto;
    background-position: center;
    background-size: cover;
  }

  .problems_heading {
    font-size: 2rem;
  }

  .testimonial_box p {
    font-size: 0.8rem;
  }

  .help_box {
    padding: 1rem 1.5rem;
  }

  .contact_section .contact_img {
    width: 80%;
    margin-bottom: 2rem;
  }

  .contact_form {
    background-color: #636363;
    padding: 1rem;
  }

  .available_img {
    transform: scale(1.3);
  }
}

